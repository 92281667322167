import {
  isPathActive,
  NAV_BRANDS_PATH,
  NAV_KIDS_PATH,
  NAV_MEN_PATH,
  NAV_WOMEN_PATH,
} from "./paths";
import { useFilter, useSorting } from "@sajari/react-hooks";
import { useMemo } from "react";
import Events from "./analytics";

export const newCategoryParam = "sort=-published_at";

export enum MenuLinkType {
  department = "department",
  all_department = "all_department",
  category = "category",
  all_category = "all_category",
  new_category = "new_category",
  subcategory = "subcategory",
}

export type LinkData = {
  t: string;
  type: MenuLinkType;
  path?: string;
  params?: string[];
  children?: LinkData[];
};

export const navLinks: LinkData[] = [
  {
    t: "web.nav_menu.designers",
    path: NAV_BRANDS_PATH,
    type: MenuLinkType.department,
  },
  {
    t: "web.nav_menu.womens",
    path: NAV_WOMEN_PATH,
    type: MenuLinkType.department,
    children: [
      {
        t: "web.nav_menu.all_womens",
        type: MenuLinkType.all_department,
      },
      {
        t: "web.nav_menu.clothing",
        params: ["category=clothing"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_clothing",
            params: ["category=clothing"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_clothing",
            params: ["category=clothing", newCategoryParam],
            type: MenuLinkType.new_category,
          },
          {
            t: "web.nav_menu.dresses",
            params: ["category=dresses"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.tops",
            params: ["category=tops"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.suits_and_blazers",
            params: ["category=suits-and-blazers"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.knitwear_and_jumpers",
            params: ["category=knitwear-and-jumpers"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.coats_and_jackets",
            params: ["category=coats-and-jackets"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.jumpsuits_and_playsuits",
            params: ["category=jumpsuits-and-playsuits"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.pants",
            params: ["category=pants"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.skirts",
            params: ["category=skirts"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.shorts",
            params: ["category=shorts"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.swimwear",
            params: ["category=swimwear"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.activewear",
            params: ["category=activewear"],
            type: MenuLinkType.subcategory,
          },
        ],
      },
      {
        t: "web.nav_menu.bags",
        params: ["category=bags"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_bags",
            params: ["category=bags"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_bags",
            params: ["category=bags", newCategoryParam],
            type: MenuLinkType.new_category,
          },
          {
            t: "web.nav_menu.handbags",
            params: ["category=handbags"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.shoulder_bags",
            params: ["category=shoulder-bags"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.crossbody_bags",
            params: ["category=crossbody-bags"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.totes",
            params: ["category=totes"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.satchels",
            params: ["category=satchels"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.clutch_bags",
            params: ["category=clutch-bags"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.mini_bags",
            params: ["category=mini-bags"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.backpacks",
            params: ["category=backpacks"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.weekender_bags",
            params: ["category=weekender-bags"],
            type: MenuLinkType.subcategory,
          },
        ],
      },
      {
        t: "web.nav_menu.shoes",
        params: ["category=shoes"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_shoes",
            params: ["category=shoes"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_shoes",
            params: ["category=shoes", newCategoryParam],
            type: MenuLinkType.new_category,
          },
        ],
      },
      {
        t: "web.nav_menu.accessories",
        params: ["category=accessories"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_accessories",
            params: ["category=accessories"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_accessories",
            params: ["category=accessories", newCategoryParam],
            type: MenuLinkType.new_category,
          },
          {
            t: "web.nav_menu.sunglasses_and_eyewear",
            params: ["category=sunglasses-and-eyewear"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.hats_and_hair_accessories",
            params: ["category=hats-and-hair-accessories"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.scarves",
            params: ["category=scarves"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.belts",
            params: ["category=belts"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.wallets",
            params: ["category=wallets"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.watches",
            params: ["category=watches"],
            type: MenuLinkType.subcategory,
          },
        ],
      },
      {
        t: "web.nav_menu.jewellery",
        params: ["category=jewellery"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_jewellery",
            params: ["category=jewellery"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_jewellery",
            params: ["category=jewellery", newCategoryParam],
            type: MenuLinkType.new_category,
          },
          {
            t: "web.nav_menu.necklaces",
            params: ["category=necklaces"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.bracelets",
            params: ["category=bracelets"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.earrings",
            params: ["category=earrings"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.rings",
            params: ["category=rings"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.body_jewellery",
            params: ["category=body-jewellery"],
            type: MenuLinkType.subcategory,
          },
        ],
      },
    ],
  },
  {
    t: "web.nav_menu.mens",
    path: NAV_MEN_PATH,
    type: MenuLinkType.department,
    children: [
      {
        t: "web.nav_menu.all_mens",
        type: MenuLinkType.all_department,
      },
      {
        t: "web.nav_menu.clothing",
        params: ["category=clothing"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_clothing",
            params: ["category=clothing"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_clothing",
            params: ["category=clothing", newCategoryParam],
            type: MenuLinkType.new_category,
          },
          {
            t: "web.nav_menu.tops",
            params: ["category=tops"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.suits_and_blazers",
            params: ["category=suits-and-blazers"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.knitwear_and_jumpers",
            params: ["category=knitwear-and-jumpers"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.coats_and_jackets",
            params: ["category=coats-and-jackets"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.jumpsuits_and_playsuits",
            params: ["category=jumpsuits-and-playsuits"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.pants",
            params: ["category=pants"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.shorts",
            params: ["category=shorts"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.swimwear",
            params: ["category=swimwear"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.activewear",
            params: ["category=activewear"],
            type: MenuLinkType.subcategory,
          },
        ],
      },
      {
        t: "web.nav_menu.shoes",
        params: ["category=shoes"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_shoes",
            params: ["category=shoes"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_shoes",
            params: ["category=shoes", newCategoryParam],
            type: MenuLinkType.new_category,
          },
        ],
      },
      {
        t: "web.nav_menu.bags",
        params: ["category=bags"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_bags",
            params: ["category=bags"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_bags",
            params: ["category=bags", newCategoryParam],
            type: MenuLinkType.new_category,
          },
        ],
      },
      {
        t: "web.nav_menu.accessories",
        params: ["category=accessories"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_accessories",
            params: ["category=accessories"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_accessories",
            params: ["category=accessories", newCategoryParam],
            type: MenuLinkType.new_category,
          },
          {
            t: "web.nav_menu.belts",
            params: ["category=belts"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.hats_and_hair_accessories",
            params: ["category=hats-and-hair-accessories"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.scarves",
            params: ["category=scarves"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.sunglasses_and_eyewear",
            params: ["category=sunglasses-and-eyewear"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.wallets",
            params: ["category=wallets"],
            type: MenuLinkType.subcategory,
          },
          {
            t: "web.nav_menu.watches",
            params: ["category=watches"],
            type: MenuLinkType.subcategory,
          },
        ],
      },
      {
        t: "web.nav_menu.jewellery",
        params: ["category=jewellery"],
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.all_jewellery",
            params: ["category=jewellery"],
            type: MenuLinkType.all_category,
          },
          {
            t: "web.nav_menu.new_jewellery",
            params: ["category=jewellery", newCategoryParam],
            type: MenuLinkType.new_category,
          },
        ],
      },
    ],
  },
  {
    t: "web.nav_menu.kids",
    path: NAV_KIDS_PATH,
    type: MenuLinkType.department,
    children: [
      {
        t: "web.nav_menu.all_kids",
        type: MenuLinkType.all_department,
      },
      {
        t: "web.nav_menu.clothing",
        params: ["category=clothing"],
        type: MenuLinkType.category,
      },
      {
        t: "web.nav_menu.shoes",
        params: ["category=shoes"],
        type: MenuLinkType.category,
      },
      {
        t: "web.nav_menu.accessories",
        params: ["category=accessories"],
        type: MenuLinkType.category,
      },
      {
        t: "web.nav_menu.bags",
        params: ["category=bags"],
        type: MenuLinkType.category,
      },
    ],
  },
  {
    t: "web.nav_menu.featured",
    type: MenuLinkType.department,
    children: [
      {
        t: "web.nav_menu.collections",
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.workwear",
            path: "/collections/37031-office-wear",
            type: MenuLinkType.category,
          },
          {
            t: "web.nav_menu.weekend",
            path: "/collections/42462-the-weekend-edit",
            type: MenuLinkType.category,
          },
          {
            t: "web.nav_menu.eventwear",
            path: "/collections/42463-eventwear",
            type: MenuLinkType.category,
          },
        ],
      },
      {
        t: "web.nav_menu.sellers",
        type: MenuLinkType.category,
        children: [
          {
            t: "web.nav_menu.airrobeconcierge",
            path: "/airrobeconcierge",
            type: MenuLinkType.category,
          },
          {
            t: "web.nav_menu.secondo",
            path: "/secondo",
            type: MenuLinkType.category,
          },
          {
            t: "web.nav_menu.cirkular",
            path: "/cirkular",
            type: MenuLinkType.category,
          },
        ],
      },
    ],
  },
];

export const isMenuItemActive = (
  currentPath: string,
  targetPath: string,
  type: MenuLinkType,
  currentParams: string[] = [],
  targetParams: string[] = [],
  children = null
): boolean => {
  // The page must match the target path
  if (!isPathActive(currentPath, targetPath)) return false;

  switch (type) {
    case MenuLinkType.department:
      return true;
    case MenuLinkType.all_department:
      return !currentParams.find((p) => p.startsWith("category"));
    case MenuLinkType.all_category:
      return (
        targetParams.every((p) => currentParams.includes(p)) &&
        !currentParams.includes(newCategoryParam)
      );
    case MenuLinkType.new_category:
      return currentParams.sort().join() === targetParams.sort().join();
    default: {
      if (children)
        return children.some(({ type, params }) =>
          isMenuItemActive(currentPath, targetPath, type, currentParams, params)
        );

      return targetParams.every((p) => currentParams.includes(p));
    }
  }
};

export const onMenuItemClick = (
  username: string,
  type: MenuLinkType,
  t: string,
  params?: string[]
) => () => {
  let category: string;

  params?.forEach((param) => {
    const keyVal = param.split("=");
    if (keyVal[0] == "category") category = keyVal[1];
  });

  Events.sendNavMenuItemClicked({
    username,
    type,
    department: t.split(".").pop(),
    category,
  });
};

export function createHref(path: string, params: string[] = []) {
  if (!path) return undefined;

  return path.concat(params.length ? "?".concat(params.join("&")) : "");
}

export function useCurrentParams() {
  const categoryFilter = useFilter("category");
  const { sorting } = useSorting();
  const category = categoryFilter.selected[0] || "";

  return useMemo(() => {
    const params = [];
    if (category) params.push(`category=${category}`);
    if (sorting) params.push(`sort=${sorting}`);
    return params;
  }, [category, sorting]);
}
