import { Pagination } from "@sajari/react-search-ui";
import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import ResultsGrid from "./ResultsGrid";
import SearchFilters from "./SearchFilters";
import { usePagination } from "@sajari/react-hooks";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import Box from "../../../elements/v2/Box/Box";
import { useSearchPageContext } from "../SearchPageContext";
import FilterTags from "./FilterTags";

const SAJARI_MAX_PAGES = 500;

export default function SearchContent({ countryCode }: { countryCode?: string }) {
  const { resultsPerPage, totalResults, page, setPage } = usePagination();
  const { isProductLongTailPage } = useSearchPageContext();

  // Sajari blocks pages over 500
  const pageCount = Math.ceil(totalResults / resultsPerPage);
  const cappedPageCount = Math.min(pageCount, SAJARI_MAX_PAGES);

  return (
    <FlexBox p={1}>
      {!isProductLongTailPage && <SearchFilters countryCode={countryCode} />}
      <Box flexGrow={1}>
        <FilterTags />
        <ResultsGrid countryCode={countryCode} />
        {!isProductLongTailPage && (
          <SearchPagination
            scrollToTop={true}
            page={page}
            pageCount={cappedPageCount}
            onChange={setPage}
          />
        )}
      </Box>
    </FlexBox>
  );
}

const SearchPagination: AnyStyledComponent = styled(Pagination)`
  justify-content: right;
  margin: 10px;
  overflow: hidden;
`;
