import { ResellFormFields } from "../../RentForm/FormModel/listingFormModel";
import { v4 as uuidv4 } from "uuid";
import { CurrencyCode } from "../../../../../types/graphql-global-types";
import { GetListingFormData_listing } from "../../queries/types/GetListingFormData";
import { calculateFloorPrice } from "../PriceSection/PriceSection";
import { DEFAULT_SHIPPING_PRICE } from "../../../../../constants/ShippingConstants";

interface Location {
  address: string;
  latitude: number;
  longitude: number;
}

interface Image {
  doneProcessing: boolean;
  doneUploading: boolean;
  listingImageId: number;
  processedURL: string;
  uuid: string;
}

export interface ResellFormFieldValues {
  condition: string;
  conditionNotes: string;
  currency: CurrencyCode;
  deliveryMethod: string[];
  location: Location;
  shippingFee: string;
  shippingFeeInternational: string;
  price: string;
  images: Image[];
  includeShippingFee: boolean;
  includeDomesticShippingFee: boolean;
  includeInternationalShippingFee: boolean;
  enableSmartPricing: boolean;
  floorPrice: string;
}

export interface ResellFormListingFormModel {
  formId: string;
  formField: ResellFormFields;
}

const form: ResellFormListingFormModel = {
  formId: "listingForm",
  formField: {
    condition: {
      name: "condition",
      label: "Condition",
      requiredErrorMsg: "Condition is required",
    },
    conditionNotes: {
      name: "conditionNotes",
      label: "Condition notes",
      requiredErrorMsg: "Condition notes are required",
    },
    currency: {
      name: "currency",
      label: "Currency",
      requiredErrorMsg: "Currency is required",
    },
    location: {
      name: "location",
      label: "Your location",
      requiredErrorMsg: "Location is required",
    },
    shippingFee: {
      name: "shippingFee",
      label: "Shipping Fee",
      requiredErrorMsg: "Shipping Fee is required",
      invalidErrorMsg: "Shipping Fee is invalid",
    },
    shippingFeeInternational: {
      name: "shippingFeeInternational",
      label: "International Shipping Fee",
      requiredErrorMsg: "International Shipping Fee is required",
      invalidErrorMsg: "International Shipping Fee is invalid",
    },
    price: {
      name: "price",
      label: "Price",
      requiredErrorMsg: "Price is required",
      invalidErrorMsg: "Price is invalid",
      minAmountErrorMsg: (minimumPrice: number) =>
        `Price must be greater than or equal to ${minimumPrice}`,
    },
    images: {
      name: "images",
      label: "Images",
      requiredErrorMsg: "At least one image is required",
      imageUploadingMsg: "Images are still uploading, please wait",
    },
    deliveryMethods: {
      name: "deliveryMethods",
      requiredErrorMsg: "Delivery method is required",
    },
    includeShippingFee: {
      name: "includeShippingFee",
      label: "Include shipping fee in price?",
    },
    includeDomesticShippingFee: {
      name: "includeDomesticShippingFee",
      label: "Offer free domestic shipping",
    },
    includeInternationalShippingFee: {
      name: "includeInternationalShippingFee",
      label: "Offer international shipping at domestic rate",
    },
    enableSmartPricing: {
      name: "enableSmartPricing",
      label: "Smart Pricing",
    },
    floorPrice: {
      name: "floorPrice",
      label: "Floor Price",
      requiredErrorMsg: "Floor Price is required",
      invalidErrorMsg: "Floor Price is invalid",
    },
  },
};

export default form;

// Given the data from the server, return initial values for the form model
export function getInitialValues(
  listing: GetListingFormData_listing,
  initialCurrency: CurrencyCode
) {
  const priceString = listing.price?.formatted || listing.estimatedResaleValue?.formatted || "";
  const priceNumber = parseFloat(priceString);
  const floorPrice = listing.floorPrice
    ? listing.floorPrice.formatted
    : priceString !== ""
    ? calculateFloorPrice(priceNumber).toString()
    : "";

  return {
    condition: listing.condition,
    conditionNotes: listing.conditionNotes || "",
    currency: listing.price ? listing.price.currency.code : initialCurrency,
    price: priceString,
    enableSmartPricing: listing.smartPricingEnabled,
    floorPrice: floorPrice,
    shippingFee:
      listing.shippingPrice?.fractional > 0
        ? listing.shippingPrice.formatted
        : DEFAULT_SHIPPING_PRICE.LOCAL,
    shippingFeeInternational:
      listing.shippingPriceInternational?.fractional > 0
        ? listing.shippingPriceInternational.formatted
        : DEFAULT_SHIPPING_PRICE.INTERNATIONAL,
    includeShippingFee:
      listing.shippingPrice === null && listing.shippingPriceInternational === null
        ? false
        : !(listing.shippingPrice?.fractional > 0) &&
          !(listing.shippingPriceInternational?.fractional > 0),
    includeDomesticShippingFee:
      listing.shippingPrice === null ? false : !(listing.shippingPrice?.fractional > 0),
    includeInternationalShippingFee:
      listing.shippingPriceInternational === null
        ? false
        : !(listing.shippingPriceInternational?.fractional > 0),
    deliveryMethods: [...listing.deliveryMethods, "shipping"],
    location: listing.location || listing.author.location,
    images: listing.listingImages.map((image) => ({
      uuid: uuidv4(),
      listingImageId: image.id,
      processedURL: image.squareUrl,
      doneUploading: true,
      doneProcessing: image.ready,
    })),
  };
}
