import { GetListing_listing_category } from "../../../pages/ListingPage/queries/types/GetListing";
import { Table, TableBody, TableCell as MuiCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const sizeTypes = ["AU", "US", "UK", "EU"];
const womensPantsSizes = {
  AU: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
  US: [0, 0, 2, 4, 6, 8, 10, 12, 14, 16],
  UK: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
  EU: [24, 26, 28, 30, 32, 34, 36, 38, 40, 42],
};
const mensPantsSizes = {
  "AU/US/UK": [26, 28, 30, 32, 34, 36, 38, 40, 42, 44],
  EU: [24, 26, 28, 30, 32, 34, 36, 38, 40, 42],
};
const shoeSizes = {
  AU: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  US: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  UK: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  EU: [35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46],
};
const clothingSizes = {
  Size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  AU: [4, 6, 8, 10, 12, 14, 16, 18],
  US: [0, 2, 4, 6, 8, 10, 12, 14],
  UK: [4, 6, 8, 10, 12, 14, 16, 18],
  EU: [32, 34, 36, 38, 40, 42, 44, 46],
};

export default function SizeTable({ category }: { category?: GetListing_listing_category }) {
  if (category?.name === "Pants") {
    return (
      <>
        <TableSection
          sizes={womensPantsSizes}
          title="Women's Pants Size Guide"
          sizeTypes={sizeTypes}
        />
        <br />
        <TableSection
          sizes={mensPantsSizes}
          title="Men's Pants Size Guide"
          sizeTypes={["AU/US/UK", "EU"]}
        />
      </>
    );
  }

  if (category?.name === "Shoes") {
    return <TableSection title="Shoe Size Guide" sizeTypes={sizeTypes} sizes={shoeSizes} />;
  }

  return (
    <TableSection
      title="Clothing Size Guide"
      sizeTypes={["Size"].concat(sizeTypes)}
      sizes={clothingSizes}
    />
  );
}

interface SizesProp {
  AU?: number[];
  US?: number[];
  UK?: number[];
  EU: number[];
  "AU/US/UK"?: number[];
}

const TableSection = ({
  title,
  sizeTypes,
  sizes,
}: {
  title: string;
  sizeTypes: string[];
  sizes: SizesProp;
}) => (
  <Container>
    <h3>{title}</h3>
    <Table>
      <TableHead>
        <TableRow>
          {sizeTypes.map((sizeType) => (
            <TableCell component="th" key={sizeType}>
              {sizeType}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sizes.EU.map((size, index) => (
          <TableRow key={index}>
            {sizeTypes.map((sizeType) => (
              <TableCell key={sizeType}>{sizes[sizeType][index]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Container>
);

const TableCell = styled(MuiCell)`
  text-align: center;
`;

const Container = styled.div`
  text-align: center;
`;
