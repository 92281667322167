import { useSearchContext, useTracking } from "@sajari/react-hooks";
import { flatten } from "lodash";
import React, { useContext } from "react";
import { MyCollectionsContext } from "../../../../contexts/MyCollectionsContext/MyCollectionsContext";
import { mapResultToListing } from "../../../../utils/listings";
import ListingCard from "../../../composites/v2/ListingCard/ListingCard";
import { useSearchPageContext } from "../SearchPageContext";
import Events from "../../../../utils/analytics";
import Box from "../../../elements/v2/Box/Box";
import styled from "styled-components";
import { breakpoints } from "../../../../utils/styledHelpers";
import { Result } from "@sajari/sdk-js";
import ProductLongTailPage from "./ProductLongTailPage";

export default function ResultsGrid({ countryCode }: { countryCode?: string }) {
  const { myCountry, collectionId, longTailPage } = useSearchPageContext();
  const { results = [], searching } = useSearchContext();
  const { tracking } = useTracking();
  const { data: myCollectionsData } = useContext(MyCollectionsContext);

  const allCollectionListingIds: number[] =
    flatten(
      myCollectionsData?.me?.collections.results.flatMap(
        (collection): number[] => collection.listingIds
      )
    ) || [];

  const myCurrency = myCountry?.currency;

  const makeClickHandler = (result) => () => {
    const { token, values } = result;
    tracking.onResultClick(values, token);

    const listing = mapResultToListing(result, allCollectionListingIds, myCurrency);
    Events.sendProductCardClicked(listing, collectionId ? "Collection" : "Search");
  };

  return (
    <Container $searching={searching} className="t-searchResults">
      {results.map((result: Result) => (
        <ListingCard
          key={result.values.id as string}
          listing={mapResultToListing(result, allCollectionListingIds, myCurrency)}
          handleClick={makeClickHandler(result)}
          countryCode={countryCode}
        />
      ))}
      <ProductLongTailPage
        myCurrency={myCurrency}
        allCollectionListingIds={allCollectionListingIds}
        makeClickHandler={makeClickHandler}
        countryCode={countryCode}
      />
    </Container>
  );
}

const Container = styled(Box)<{ $searching?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  padding: 20px;
  ${({ $searching }) => $searching && `opacity: 0.5;`}
  ${breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${breakpoints.down("sm")} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    padding: 10px;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
  }
`;
