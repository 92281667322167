import React, { useEffect, useMemo } from "react";
import {
  AddressElement as BillingAddress,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import {
  DefaultValuesOption,
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions,
} from "@stripe/stripe-js";
import { FormikProps } from "formik";
import { FormValues, UserProps } from "../CheckoutPage";
import { Section } from "../elements/Section";
import Header from "../elements/Header";
import Box from "../../../elements/v2/Box/Box";
import Address from "./Address";
import { Checkbox } from "@material-ui/core";
import { Span } from "../../../elements/v2/Typography/Typography";
import styled from "styled-components";

export type SetBillingAddressType = (
  address:
    | React.Dispatch<
        React.SetStateAction<
          StripeAddressElementOptions["defaultValues"] | DefaultValuesOption["billingDetails"]
        >
      >
    | StripeAddressElementOptions["defaultValues"]
    | DefaultValuesOption["billingDetails"]
) => void;

interface BillingProps {
  setBillingAddress: SetBillingAddressType;
  isBillingSameAsShipping: boolean;
  setIsBillingSameAsShipping: (any: boolean) => void;
}

export default function CheckoutAddressSection({
  shippingRequired,
  googleMapsApiKey,
  formik,
  emailAddress,
  currentUser,
  billingProps: { setIsBillingSameAsShipping, isBillingSameAsShipping, setBillingAddress },
  setAddressError,
  handleOnChangeRef,
}: {
  formik: FormikProps<FormValues>;
  shippingRequired: boolean;
  googleMapsApiKey: string;
  emailAddress: string;
  currentUser: UserProps;
  setAddressError: (any: boolean) => void;
  billingProps: BillingProps;
  handleOnChangeRef: React.MutableRefObject<(event: StripeAddressElementChangeEvent) => void>;
}) {
  const handleOnChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete) {
      // Extract potentially complete address
      // Mapping fields here helps preserve existing logic in our backend
      const address = event.value.address;
      setBillingAddress({
        name: event.value.name,
        address: address,
      });
    }
  };

  useEffect(() => {
    handleOnChangeRef.current = handleOnChange;
    if (currentUser.address) formik.setFieldValue("shipping_address", currentUser.address);
    formik.setFieldValue("shipping_address.name", currentUser.name);
  }, [handleOnChangeRef]);

  const defaultAddressValues = useMemo(() => {
    let values: StripeAddressElementOptions["defaultValues"] = {
      name: currentUser.name,
    };
    if (currentUser.address)
      values = {
        ...values,
        address: {
          line1: currentUser.address.street1 || "",
          country: currentUser.address?.country,
          line2: currentUser.address.street2 || "",
          city: currentUser.address.city || "",
          state: currentUser.address.state_or_province,
          postal_code: currentUser.address.postal_code || "",
        },
      };
    return values;
  }, []);

  const handleCheckboxChange = (event) => {
    setIsBillingSameAsShipping(event.target.checked);
    event.target.checked && setAddressError(false);
  };

  return (
    <Section>
      <Header>{shippingRequired ? "Shipping " : "Billing "}Address</Header>
      <Box mb={1} width="100%">
        <LinkAuthenticationElement
          options={{
            defaultValues: {
              email: emailAddress,
            },
          }}
        />
      </Box>
      {shippingRequired && (
        <>
          <Address
            defaultValues={defaultAddressValues}
            {...{
              setBillingAddress,
              setAddressError,
              googleMapsApiKey,
              formik,
              isBillingSameAsShipping,
            }}
          />
          <Space />
          <CheckboxText>
            <Checkbox
              id="is-billing-same-as-shipping"
              checked={isBillingSameAsShipping}
              onChange={handleCheckboxChange}
            />
            Billing address is the same as shipping address.
          </CheckboxText>
          {!isBillingSameAsShipping && <Space />}
        </>
      )}
      <Box id="billing-element" mb={2}>
        {(!isBillingSameAsShipping || !shippingRequired) && (
          <>
            {shippingRequired && <Header>Billing Address</Header>}
            <BillingAddress
              onChange={handleOnChange}
              options={{
                mode: "billing",
                autocomplete: {
                  mode: "google_maps_api",
                  apiKey: googleMapsApiKey,
                },
                defaultValues: defaultAddressValues,
              }}
            />
          </>
        )}
      </Box>
    </Section>
  );
}

const CheckboxText = styled(Span)`
  font-size: 12px;
`;

const Space = styled.div`
  height: 16px;
`;
