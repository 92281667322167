import React from "react";
import styled from "styled-components";
import { useFilter } from "@sajari/react-hooks";
import { map, pickBy, xor } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import Box from "../../../elements/v2/Box/Box";
import { breakpoints } from "../../../../utils/styledHelpers";
import { useSearchPageContext } from "../SearchPageContext";
import { BUTTON_SIZES, OutlineButton } from "../../../elements/v2/Button/Button";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import SearchSort from "./SearchSort";
import { humanizeDealsLabel } from "./SearchFilters";

export default function FilterTags() {
  const { setMobileSidebarOpen, isProductLongTailPage } = useSearchPageContext();

  if (isProductLongTailPage) return null;

  const priceFilter = useFilter("price");
  const brandFilter = useFilter("brand");
  const categoryFilter = useFilter("category");
  const colorFilter = useFilter("colour");
  const conditionFilter = useFilter("condition");
  const sizeFilter = useFilter("size");
  const patternFilter = useFilter("pattern");
  const occasionFilter = useFilter("occasion");
  const lengthFilter = useFilter("length");
  const materialFilter = useFilter("material");
  const styleFilter = useFilter("style");
  const dealsFilter = useFilter("price_reduced_percentage_bucket");

  const filters = {
    Price: priceFilter,
    Brand: brandFilter,
    Category: categoryFilter,
    Colour: colorFilter,
    Condition: conditionFilter,
    Size: sizeFilter,
    Pattern: patternFilter,
    Occasion: occasionFilter,
    Length: lengthFilter,
    Material: materialFilter,
    Style: styleFilter,
    Deals: dealsFilter,
  };

  type Keys = keyof typeof filters;
  type Filter = typeof filters[Keys];

  // After filters have at least one selection
  const activeFilters = pickBy(filters, (filter) => filter.selected.length > 0);

  // To remove a filter, we pop it from the array
  function removeSelectedFilter(filter: Filter, value: string) {
    filter.setSelected(xor(filter.selected, [value]));
  }

  function toggleOpen() {
    setMobileSidebarOpen((o) => !o);
  }

  function displaySelectedFilterValue(filterType: Keys, value: string) {
    if (filterType === "Size" || filterType === "Material" || filterType === "Style")
      return value.replace(/\|/g, " - ");
    if (filterType === "Deals") return humanizeDealsLabel(value);
    return value;
  }

  return (
    <Box p={0.5}>
      <MobileOnly flexDirection="row" display="flex">
        <OutlineButton onClick={toggleOpen} $fullWidth size={BUTTON_SIZES.MEDIUM}>
          Filter
        </OutlineButton>
        <SearchSort />
      </MobileOnly>
      <Tags>
        {map(activeFilters, (filter, filterType: Keys) =>
          filter.selected.map((value) => (
            <Tag
              key="key"
              endIcon={<CloseIcon />}
              onClick={() => removeSelectedFilter(filter, value)}
            >
              {displaySelectedFilterValue(filterType, value)}
            </Tag>
          ))
        )}
      </Tags>
    </Box>
  );
}

const Tags = styled(FlexBox)`
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 8px;
  flex-wrap: wrap;
  ${breakpoints.up("tablet")} {
    padding: 0;
  }
`;

const Tag = styled(OutlineButton).attrs({
  size: BUTTON_SIZES.SMALL,
})`
  text-transform: initial;
  min-height: 32px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
`;

const MobileOnly = styled(Box)`
  flex-direction: row;
  display: flex;

  ${breakpoints.up("tablet")} {
    display: none;
  }
`;
