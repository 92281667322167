import React from "react";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box from "../../../../elements/v2/Box/Box";
import FilteredProductSlider from "./FilteredProductSlider";
import { Variables } from "@sajari/react-hooks";
import { pickBy } from "lodash";
import {
  getDealsFilters,
  getSimilarFilters,
  getMoreBrandFilters,
  getMoreSizeFilters,
  getRecentlyViewedListingFilters,
  getMoreFromSameAuthorFilters,
} from "./filters";
import Events from "../../../../../utils/analytics";
import { getCustomFieldSize } from "../Size/Size";
import { SellerType } from "../../../../../types/graphql-global-types";

export type ProductRecommendationsProps = {
  listing?: GetListing_listing;
  latitude?: string;
  longitude?: string;
  countryCode?: string;
};

export default function ProductRecommendations({
  listing,
  latitude,
  longitude,
  countryCode,
}: ProductRecommendationsProps) {
  const getVariables = (filter: string) =>
    new Variables(
      pickBy({
        filter,
        resultsPerPage: 10,
        inputCenterLat: latitude,
        inputCenterLng: longitude,
      })
    );

  const brand = listing.brand;
  const category = listing.category.name;
  const size = getCustomFieldSize(listing);

  const makeListingClickHandler = (carouselType: string) => (listing) => {
    Events.sendProductCardClicked(listing, "PDP Carousel", {
      carouselType,
    });
  };

  const sameAuthorFilter = getMoreFromSameAuthorFilters(listing);
  const dealsFilter = getDealsFilters(listing);
  const similarFilter = getSimilarFilters(listing);
  const moreBrandFilter = getMoreBrandFilters(listing);
  const moreSizeFilter = getMoreSizeFilters(listing);
  const recentlyViewedListingFilter = getRecentlyViewedListingFilters(listing);

  return (
    <Box id="product-recommendations">
      {listing.author.sellerType === SellerType.professional_seller && (
        <FilteredProductSlider
          heading={`More Listings from ${listing.author.displayName || listing.author.givenName}`}
          variables={getVariables(sameAuthorFilter)}
          handleListingClick={makeListingClickHandler("Same Author")}
          countryCode={countryCode}
        />
      )}
      <FilteredProductSlider
        heading="Deals on Similar Listings"
        variables={getVariables(dealsFilter)}
        handleListingClick={makeListingClickHandler("Discounted")}
        countryCode={countryCode}
      />
      <FilteredProductSlider
        heading={`${brand} ${category}`}
        variables={getVariables(similarFilter)}
        handleListingClick={makeListingClickHandler("Narrow")}
        countryCode={countryCode}
      />
      {similarFilter !== moreBrandFilter && (
        <FilteredProductSlider
          heading={`More ${brand} ${category}`}
          variables={getVariables(moreBrandFilter)}
          handleListingClick={makeListingClickHandler("Wider")}
          countryCode={countryCode}
        />
      )}
      <FilteredProductSlider
        heading={`More ${size ? `Size (${size}) ` : ""}${category}`}
        variables={getVariables(moreSizeFilter)}
        handleListingClick={makeListingClickHandler("Widest")}
        countryCode={countryCode}
      />
      {recentlyViewedListingFilter && (
        <FilteredProductSlider
          heading="Recently Viewed"
          variables={getVariables(recentlyViewedListingFilter)}
          handleListingClick={makeListingClickHandler("Recently Viewed")}
          countryCode={countryCode}
        />
      )}
    </Box>
  );
}
