import { useFilter } from "@sajari/react-hooks";
import { groupBy, map, xor } from "lodash";
import React from "react";
import titleize from "../../../../../utils/titleize";
import FilterChip from "../FilterChip";
import { Small } from "../../../../elements/v2/Typography/Typography";
import Box from "../../../../elements/v2/Box/Box";
import { FilterNames } from "../../../../../utils/filters";
import { filterSizesByCountry } from "../../../../../utils/listingHelper";

type Props = {
  filterName: FilterNames;
  countryCode?: string;
};
export default function SubFieldFilter({ filterName, countryCode }: Props) {
  const { setSelected, options, selected } = useFilter(filterName);

  const groupedOptions = groupBy(options, (option) => titleize(option.label.split("|")[0]));

  function handleToggle(option) {
    setSelected(xor(selected, [option.label]));
  }

  function renderLabel(label: string) {
    if (filterName !== FilterNames.size) return label;

    return filterSizesByCountry(label, countryCode);
  }

  return (
    <>
      {map(groupedOptions, (options, key) => (
        <div key={key} className="t-searchFilterGroup" style={{ marginBottom: "24px" }}>
          <Box mb={1}>
            <Small>{key}</Small>
          </Box>
          {map(options, (option) => (
            <FilterChip
              key={option.value}
              label={renderLabel(option.label.split("|").slice(1).join("|"))}
              onClick={() => handleToggle(option)}
              $selected={selected.includes(option.label)}
              size="small"
            />
          ))}
        </div>
      ))}
    </>
  );
}
