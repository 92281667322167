import { ListingCardFragment } from "../graphql/fragments/types/ListingCardFragment";
import { ListingData } from "./listings";

//from listing_document_builder.rb
const sizeFields = ["Tops & dresses", "Bottoms", "Shoes", "Accessories"];

export const extractListingSizeText = (
  listing: Partial<ListingCardFragment> | ListingData,
  countryCode = null
) => {
  const sizes = "size" in listing ? listing.size : null;
  if (sizes == null) return;

  let text = "";
  //sizes are in the format "<sizeField>| <size>" and we don't want "<sizeField>|" bit
  sizes.forEach((s) => {
    sizeFields.forEach((field) => {
      if (s.includes(field)) text += s.replace(`${field}|`, "") + ", ";
    });
  });
  text = text.trim().slice(0, -1); //remove whitespace and comma at the end
  if (countryCode) text = filterSizesByCountry(text, countryCode);

  return text;
};

const COUNTRY_SIZE_MAP = {
  US: "US",
  CA: "US",
  MX: "US",
  PR: "US",
  ES: "EU",
  AT: "EU",
  BG: "EU",
  BE: "EU",
  EE: "EU",
  IE: "EU",
  FI: "EU",
  CY: "EU",
  GR: "EU",
  CZ: "EU",
  FR: "EU",
  LV: "EU",
  LT: "EU",
  LU: "EU",
  EU: "EU",
  DE: "EU",
  IT: "EU",
  MT: "EU",
  NL: "EU",
  UK: "UK",
  CH: "UK",
  CHF: "UK",
  GB: "UK",
  SE: "EU",
  PT: "EU",
  PL: "EU",
  RO: "EU",
  SK: "EU",
  SI: "EU",
  AU: "AU",
  NZ: "AU",
};

export function filterSizesByCountry(sizeString, countryCode) {
  if (!sizeString || !countryCode) return sizeString;

  countryCode = COUNTRY_SIZE_MAP[countryCode] || countryCode;

  // Split the string by either comma, slash, parentheses or pipe
  const sizeParts = sizeString.split(/[\s,/()|]+/);

  // Filter and map the size parts to find the matching country code and size
  let result = sizeParts.reduce((acc: Array<string | number>, sizePart: string, i: number) => {
    if (sizePart.startsWith(countryCode)) {
      // Find the size number after the country code
      const sizeNumber = sizeParts.slice(i + 1).find((part) => /^\d*\.?\d*$/.test(part));
      if (sizeNumber && !acc.includes(sizeNumber)) acc.push(sizeNumber);
    } else if (/\b(XXS|XS|S|M|L|XL|XXL|XXXL)\b/i.test(sizePart) && !acc.includes(sizePart)) {
      acc.push(sizePart);
    }
    return acc;
  }, []);

  // return original string if no result
  return result.length > 0 ? `${countryCode} ${result.join(" | ")}` : sizeString;
}

//removes size strings from the given text (check samples below)
export const removeSizeText = (title: string): string =>
  title
    .replace(/(\-|,|\s|\(|\/|\))+size(\s|\-|\d\.?\d?|US|UK|EU|AU|Italian|\(|\/|\))+/gi, " ")
    .trim();
/*
Cotton Hugh Dress - size 8 -> 100% Cotton Hugh Dress
Silk gathered dress with strap detail, Size 8 -> Silk gathered dress with strap detail
Skinny Jeans in Mid Blue - Size 32 -> Skinny Jeans in Mid Blue
Time Is Now Skirt - Size 8 -> Time Is Now Skirt
Silk and copper size 10 dress. Very unusual! -> Silk and copper dress. Very unusual!
Ruby Nubuck Ballet Flats Blue - Size EU39 -> Ruby Nubuck Ballet Flats Blue
Oversized Unitees 3 Hoodie -> Oversized Unitees Hoodie
pants, Size Italian 42 -> pants
Japan S - Men's Size 7 -> Japan S
Odyssey React 2 Flyknit Running Shoes (Size US 10 / EU 42) -> Odyssey React 2 Flyknit Running Shoes
*/

export const removeBrandText = (title: string, brand: string): string =>
  title.toLowerCase().replace(brand.toLowerCase(), "").trim();
