import InfoTooltip from "../../FormControls/InfoTooltip/InfoTooltip";
import React, { ReactElement } from "react";
import { GetListing_listing_category } from "../../../pages/ListingPage/queries/types/GetListing";
import SizeTable from "./SizeTable";

export default function SizeGuide({
  category,
  icon,
}: {
  category?: GetListing_listing_category;
  icon?: ReactElement;
}) {
  return <InfoTooltip title={<SizeTable category={category} />} displayTime={6000} icon={icon} />;
}
