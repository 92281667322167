import React, { useMemo } from "react";
import { Pipeline, useSearch } from "@sajari/react-hooks";
import pipelineConfigClient from "../../../../utils/search/pipelineConfigClient";
import { useSearchPageContext } from "../SearchPageContext";
import { LoadingSpinnerCentered } from "../../../elements/v2/LoadingSpinner/LoadingSpinner";
import { Variables } from "@sajari/react-search-ui";
import { pickBy } from "lodash";
import { mapResultToListing } from "../../../../utils/listings";
import ListingCard from "../../../composites/v2/ListingCard/ListingCard";
import { Result } from "@sajari/sdk-js";
import { GetSearchProps_myCountry_currency } from "../queries/types/GetSearchProps";

interface Props {
  myCurrency: GetSearchProps_myCountry_currency;
  allCollectionListingIds: number[];
  makeClickHandler: (result: Result) => () => void;
  countryCode?: string;
}

function dedupeResults(results: Result[], longTailPageItemIds: string[]) {
  if (!longTailPageItemIds) return results;
  return results.filter((result) => !longTailPageItemIds.includes(result.values.id as string));
}

export default function ProductLongTailPage({
  myCurrency,
  allCollectionListingIds,
  makeClickHandler,
  countryCode,
}: Props) {
  const { longTailPage } = useSearchPageContext();

  if (!longTailPage?.isProductLongTailPage && !longTailPage?.productLongTailPageItemIds)
    return null;

  const variables = new Variables(
    pickBy({
      q: longTailPage?.title.split(" ")[0],
      resultsPerPage: 40,
    })
  );
  const pipeline = useMemo(() => new Pipeline(pipelineConfigClient, "app"), []);
  const { results = [], searching } = useSearch({ variables, pipeline });

  const dedupedResults = dedupeResults(results, longTailPage?.productLongTailPageItemIds);

  return (
    <>
      {searching ? (
        <LoadingSpinnerCentered />
      ) : (
        dedupedResults.map((result: Result) => (
          <ListingCard
            key={result.values.id as string}
            listing={mapResultToListing(result, allCollectionListingIds, myCurrency)}
            handleClick={makeClickHandler(result)}
            countryCode={countryCode}
          />
        ))
      )}
    </>
  );
}
