import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Section } from "../elements/Section";
import styled from "styled-components";
import { Span } from "../../../elements/v2/Typography/Typography";
import { MenuItem } from "@material-ui/core";
import allCountries from "country-region-data/data.json";
import TextField from "../../../composites/FormControls/TextField/TextField";
import Box from "../../../elements/v2/Box/Box";
import { DefaultValuesOption, StripeAddressElementOptions } from "@stripe/stripe-js";
import { Select } from "../../../elements/v2/Form/Select/Select";
import GoogleAddressField, {
  PlaceResult,
} from "../../../composites/v2/GoogleAddressField/GoogleAddressField";
import { SetBillingAddressType } from "./CheckoutAddressSection";
import { FormikProps } from "formik";
import { FormValues } from "../CheckoutPage";

interface Country {
  countryName: string;
  countryShortCode: string;
}

interface State {
  name: string;
  shortCode?: string;
}

interface Props {
  formik: FormikProps<FormValues>;
  defaultValues?: StripeAddressElementOptions["defaultValues"];
  setBillingAddress: SetBillingAddressType;
  isBillingSameAsShipping: boolean;
  setAddressError: (any: boolean) => void;
  googleMapsApiKey: string;
}

const STATES_INITIAL_STATE = [
  {
    name: "Australian Capital Territory",
    shortCode: "ACT",
  },
  {
    name: "New South Wales",
    shortCode: "NSW",
  },
  {
    name: "Northern Territory",
    shortCode: "NT",
  },
  {
    name: "Queensland",
    shortCode: "QLD",
  },
  {
    name: "South Australia",
    shortCode: "SA",
  },
  {
    name: "Tasmania",
    shortCode: "TAS",
  },
  {
    name: "Victoria",
    shortCode: "VIC",
  },
  {
    name: "Western Australia",
    shortCode: "WA",
  },
];

interface Error {
  touched?: boolean;
  error?: boolean;
}

interface Errors {
  name?: Error;
  street1?: Error;
  street2?: Error;
  city?: Error;
  state_or_province?: Error;
  postal_code?: Error;
}

const Address = React.memo(
  ({
    defaultValues,
    setBillingAddress,
    setAddressError,
    googleMapsApiKey,
    formik,
    isBillingSameAsShipping,
  }: Props) => {
    const initialCountry = useMemo(
      () =>
        allCountries.find(
          (country: Country) =>
            country.countryName === defaultValues.address?.country ||
            country.countryShortCode === defaultValues.address?.country
        ),
      [defaultValues.address?.country]
    );
    const initialCountryName = initialCountry?.countryName || defaultValues.address?.country;

    const [menuStates, setMenuStates] = useState<State[]>(
      initialCountry?.regions || STATES_INITIAL_STATE
    );
    const initialStateName = menuStates[0]?.name || defaultValues.address?.state;
    const [selectedStateName, setSelectedState] = useState(initialStateName);

    const [errors, setErrors] = useState<Errors>({});
    const errorMsg = "Required.";

    const setCountry = useCallback(
      (countryName: string, stateName: string = null) => {
        const country = allCountries.find(
          (country: Country) =>
            country.countryName === countryName || country.countryShortCode === countryName
        );

        const countryStates = country.regions;

        setMenuStates(countryStates);

        let state = countryStates.find((state) => state.name === stateName);

        setSelectedState(state?.name || countryStates[0]?.name);

        formik.setFieldValue("shipping_address.country", country.countryName);
        formik.setFieldValue(
          "shipping_address.state_or_province",
          state?.name || countryStates[0]?.name
        );

        if (isBillingSameAsShipping)
          setBillingAddress((prevValues) => {
            return {
              ...prevValues,
              address: {
                ...prevValues["address"],
                state: state?.shortCode || state?.name || countryStates[0]?.shortCode,
                country: country.countryShortCode,
              },
            };
          });
      },
      [allCountries, setBillingAddress]
    );

    const populatePlace = useCallback(
      ({ address_components }: PlaceResult) => {
        let address: DefaultValuesOption["billingDetails"]["address"] = {
          line1: "",
          line2: "",
          city: "",
          country: "",
          state: "",
          postal_code: "",
        };

        address_components.forEach((component) => {
          if (component["types"].includes("street_number")) address.line1 = component["long_name"];
          if (component["types"].includes("route")) address.line1 += ` ${component["long_name"]}`;
          if (component["types"].includes("subpremise")) address.line2 = component["long_name"];
          if (component["types"].includes("locality")) address.city = component["long_name"];
          if (component["types"].includes("administrative_area_level_1"))
            address.state = component["long_name"];
          if (component["types"].includes("country")) address.country = component["long_name"];
          if (component["types"].includes("postal_code"))
            address.postal_code = component["long_name"];
        });

        const { country, state, ...remainingAddress } = address;
        setCountry(country, state);

        formik.setFieldValue("shipping_address.street1", remainingAddress.line1);
        formik.setFieldValue("shipping_address.street2", remainingAddress.line2);
        formik.setFieldValue("shipping_address.city", remainingAddress.city);
        formik.setFieldValue("shipping_address.postal_code", remainingAddress.postal_code);

        if (isBillingSameAsShipping)
          setBillingAddress((prevValues) => ({
            ...prevValues,
            address: {
              ...prevValues["address"],
              ...remainingAddress,
            },
          }));
      },
      [setCountry, setBillingAddress]
    );

    function handleCountryChange({
      target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      setCountry(value);
    }

    const handleStateChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setSelectedState(event.target.value);
      handleChange(event);
    };

    const errorCheck = useCallback(
      ({
        type,
        target: { name, value },
      }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
        setErrors((prevValues) => {
          return {
            ...prevValues,
            [name]: {
              touched: prevValues[name]?.touched || type === "blur",
              error: name !== "street2" && value === "",
            },
          };
        }),
      []
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (event === null) return;

        const {
          target: { name, value },
        } = event;

        const stateName = menuStates.find((state) => state.name === value)?.name;

        formik.setFieldValue(`shipping_address.${name}`, stateName || value);

        if (isBillingSameAsShipping) {
          if (name === "name") {
            setBillingAddress((prevValues) => {
              return {
                ...prevValues,
                name: value,
              };
            });
          } else {
            setBillingAddress((prevValues) => {
              return {
                ...prevValues,
                address: {
                  ...prevValues["address"],
                  [name]: stateName || value,
                },
              };
            });
          }
        }

        errorCheck(event);
      },
      [setBillingAddress, menuStates, errorCheck]
    );

    useEffect(() => {
      for (const [key, value] of Object.entries(errors)) {
        if (value?.error) {
          setAddressError(true);
          return;
        }
      }
      setAddressError(false);
    }, [errors]);

    return (
      <Section>
        <Box mt={2}>
          <TextFieldStyled
            id="name"
            name="name"
            label="Name"
            value={formik.values.shipping_address.name}
            onChange={handleChange}
            onBlur={errorCheck}
            error={errors?.name?.touched && errors?.name?.error}
            errorMessage={errorMsg}
          />
        </Box>
        <Box mt={2}>
          <Select
            id="country"
            name="country"
            onChange={(event) => handleCountryChange(event)}
            defaultValue={initialCountryName}
            renderValue={(selected) => {
              return (
                <>
                  <LabelWithInputValue>Country or region</LabelWithInputValue>
                  <InputValueRendered>{selected || "Select a country"}</InputValueRendered>
                </>
              );
            }}
          >
            {allCountries.map((country: Country) => (
              <MenuItem key={country.countryName} value={country.countryName}>
                {country.countryName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={2}>
          <GoogleAddressField
            id="street1"
            name="street1"
            apiKey={googleMapsApiKey}
            label="Address line 1"
            value={formik.values.shipping_address.street1}
            onChange={handleChange}
            onBlur={errorCheck}
            error={errors?.street1?.touched && errors?.street1?.error}
            errorMessage={errorMsg}
            onPlaceSelection={populatePlace}
          />
        </Box>
        <Box mt={2}>
          <TextFieldStyled
            id="street2"
            name="street2"
            label="Address line 2"
            value={formik.values.shipping_address.street2}
            onChange={handleChange}
            onBlur={errorCheck}
            error={errors?.street2?.touched && errors?.street2?.error}
            errorMessage={errorMsg}
          />
        </Box>
        <Box mt={2}>
          <TextFieldStyled
            id="city"
            name="city"
            label="Suburb or city"
            value={formik.values.shipping_address.city}
            onChange={handleChange}
            onBlur={errorCheck}
            error={errors?.city?.touched && errors?.city?.error}
            errorMessage={errorMsg}
          />
        </Box>
        <Box mt={2}>
          <Select
            id="state_or_province"
            name="state_or_province"
            onChange={handleStateChange}
            onBlur={errorCheck}
            error={errors?.state_or_province?.touched && errors?.state_or_province?.error}
            errorMessage={errorMsg}
            value={selectedStateName}
            displayEmpty={true}
            renderValue={(selected) => {
              return (
                <>
                  <LabelWithInputValue>State</LabelWithInputValue>
                  <InputValueRendered>{selected || "Select a state"}</InputValueRendered>
                </>
              );
            }}
          >
            {menuStates.map((state: State) => (
              <MenuItem key={state.name} value={state.name}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={2}>
          <TextFieldStyled
            id="postal_code"
            name="postal_code"
            label="Zip / Post Code"
            value={formik.values.shipping_address.postal_code}
            onChange={handleChange}
            onBlur={errorCheck}
            error={errors?.postal_code?.touched && errors?.postal_code?.error}
            errorMessage={errorMsg}
          />
        </Box>
      </Section>
    );
  }
);

export default Address;

const TextFieldStyled = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-input {
    padding-top: 24.5px;
    padding-bottom: 10.5px;
    padding-left: 13px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px;
    height: 57px;
    font-family: Arial, sans-serif;
    font-size: 16px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid hsla(210, 96%, 45%, 50%);
  }
  div.Mui-focused {
    outline: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02),
      0 0 0 3px hsla(210, 96%, 45%, 25%) !important;
  }
  .MuiInputLabel-outlined {
    font-family: Arial, serif;
    font-size: 16px;
    transform: translate(13px, 23px);
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(13px, 10px) scale(0.62);
  }
  legend {
    max-width: 0;
  }
`;

const SpanStyled = styled(Span)`
  color: #767676;
`;

const LabelWithInputValue = styled(SpanStyled)`
  font-size: 8px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  position: absolute;
  top: 6px;
  left: 13px;
`;

const InputValueRendered = styled(MenuItem)`
  margin-top: 8px;
  padding-left: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
`;
